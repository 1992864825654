import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError, toastSuccess } from "../../utils/Toast";

const CategoryList = () => {
  const [categoryList, setcategoryList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginatedData, setPaginatedData] = useState({});
  const [categoryType, setCategoryType] = useState(null);
  const navigate = useNavigate();
  const size = 20;

  const addCategory = () => {
    navigate("/categories/add");
  };

  const getCategoryList = () => {
    setLoading(true);
    let param = `?page=${page}&size=${size}&search=${searchTerm}`;
    if (categoryType != null && categoryType != "")
      param += "&type=" + categoryType;
    Http.GET("categoryList", param)
      .then((response) => {
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...categoryList, ...data.data];
        }
        setcategoryList(data.data);
        setLoading(false);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getCategoryList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getCategoryList();
    } else {
      setPage(0);
    }
  };

  const onDeleteCategory = async (catId) => {
    setLoading(true);
    await Http.DELETE("deleteCategory", {}, `${catId}`)
      .then(({ data }) => {
        setLoading(false);
        let deleteResult = categoryList.filter((item) => item.id !== catId);
        setcategoryList(deleteResult);
        toastSuccess("Successfully deleted");
      })
      .catch(({ response }) => {
        setLoading(false);
        toastError("Request was unsuccessful.");
      });
  };

  useEffect(() => {
    getCategoryList();
  }, [page, categoryType]);

  return (
    <LoaderComponent loading={loading}>
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {categoryList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>

          <div className="categorie-type">
            <select
              name="cType"
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              className="form-select form-select-sm"
            >
              <option value="">Category Type</option>
              <option value="BUY">Buy</option>
              <option value="SELL">Sell</option>
              <option value="REPAIR">Repair</option>
            </select>
          </div>

          <button
            type="submit"
            className="btn green btn-sm rounded-0"
            onClick={addCategory}
          >
            <span className="hide-mobile me-1">Add Catergory</span>
            <img src="/icons/plus.svg" alt="add" />
          </button>
        </div>

        <div
          className="table-responsive scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categoryList &&
                  categoryList.map((item) => (
                    <tr>
                      <td>
                        <img
                          src={item?.logo?.docUrl}
                          alt="Logo"
                          className="logo"
                        />
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.code}</td>
                      <td>{item?.type}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span
                            className="cursor-pointer mx-2"
                            onClick={() =>
                              navigate("/categories/edit/" + item.id)
                            }
                          >
                            <img src="/icons/edit.svg" alt="edit" />
                          </span>

                          <span
                            className="cursor-pointer mx-2"
                            onClick={() => onDeleteCategory(item.id)}
                          >
                            <img src="/icons/delete.svg" alt="delete" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {categoryList.length === 0 && (
              <p className="empty-state">No category found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default CategoryList;
