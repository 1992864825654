import React from "react";

const Dasdboard = () => {
    return (
        <div className="p-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Welcome to our dashboard</h5>
                    <p className="card-text">
                        Some quick example text to build on the card title and make up the bulk of
                        the card's content.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dasdboard;
