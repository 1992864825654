import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import questionList from "./QuestionList";

const SelectQuestions = ({
    showSelectQestion,
    setShowSelectQestion,
    product,
    productQuestionList,
}) => {
    const [questionList, setQuestionList] = useState([]);

    const getQuestionList = () => {
        if( product === undefined || product.categoryType === undefined )
            return;
        Http.GET("questionList", `?page=0&size=100&category_type=${product.categoryType}`)
            .then((response) => {
                /*let selectedOriginalQuestionId = productQuestionList.map((item) => {
                    return item.originalId;
                });
                let data = response.data.data.map((item) => {
                    item["isChecked"] = selectedOriginalQuestionId.includes(item.id);
                    return item;
                });
                setQuestionList( data );*/

                updateSelectedProductQuestion( response.data.data );
            })
            .catch((response) => {
                toastError("Something went wrong!");
            });
    };

    const updateSelectedProductQuestion = ( data = questionList ) => {
        let selectedOriginalQuestionId = productQuestionList.map((item) => {
            return item.originalId;
        });
        data = data.map((item) => {
            item["isChecked"] = selectedOriginalQuestionId.includes(item.id);
            return item;
        });
        setQuestionList(data);
    }

    const setChecked = (isChecked, index) => {
        let questions = [...questionList];
        questions[index]["isChecked"] = isChecked;
        let selectedOriginalQuestionId = productQuestionList.map((item) => {
            return item.originalId;
        });
        if (selectedOriginalQuestionId.includes(questions[index].id))
            questions[index]["isChecked"] = true;
        setQuestionList(questions);
    };

    const addQuestionsToProduct = () => {
        let selectedOriginalQuestionId = productQuestionList.map((item) => {
            return item.originalId;
        });
        let addedQuestions = questionList
            .filter((item) => {
                return item.isChecked && !selectedOriginalQuestionId.includes(item.id);
            })
            .map((item) => {
                return {
                    productId: product.id,
                    questionId: item.id,
                };
            });

        Http.POST("addProductQuestion", addedQuestions)
            .then((response) => {
                setShowSelectQestion(false);
                toastSuccess("Successfully added");
            })
            .catch((error) => {
                toastError("Something went wrong");
            });
    };

    useEffect(() => {
        getQuestionList();
    }, [product]);

    useEffect(() => {
        updateSelectedProductQuestion();
    }, [productQuestionList]);

    const handleClose = () => setShowSelectQestion(false);
    return (
        <>
            <Modal show={showSelectQestion} onHide={handleClose} className="questions-select-modal">
                <Modal.Header closeButton>
                    <p>Selected Questions</p>
                </Modal.Header>
                <Modal.Body>
                    <div className="question-list">
                        {questionList &&
                            questionList.map((item, index) => (
                                <div className="single-question" key={"selected_question_" + index}>
                                    <div className="title d-flex">
                                        <div className="select">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={item.isChecked}
                                                id="flexCheckDefault"
                                                onChange={(e) =>
                                                    setChecked(e.target.checked, index)
                                                }
                                            />
                                        </div>
                                        <p className="regular-14">
                                            {index + 1}. {item?.title}
                                        </p>
                                    </div>
                                    <div className="types d-flex mt-1">
                                        <p className="regular-12 pb-1">
                                            Amount Type:{" "}
                                            <span className="dark">{item?.amountType}</span>{" "}
                                        </p>
                                        <p className="regular-12 ms-6">
                                            Amount: <span className="dark">{item?.amount}</span>{" "}
                                        </p>
                                    </div>
                                    <div className="options mt-2">
                                        <p className="regular-12 weight-500 dark mb-2">Options</p>

                                        {item?.optionResponseList &&
                                            item.optionResponseList.map((option, optionIndex) => (
                                                <div
                                                    className="single-option"
                                                    key={"option_" + option.id}
                                                >
                                                    <p className="regular-12 dark">
                                                        {optionIndex + 1}. {option?.title}
                                                    </p>
                                                    <div className="option-values d-flex">
                                                        <p className="regular-12 pb-1">
                                                            Amount Type:{" "}
                                                            <span className="dark">
                                                                {option?.amountType}
                                                            </span>{" "}
                                                        </p>
                                                        <p className="regular-12 ms-6">
                                                            Amount:{" "}
                                                            <span className="dark">
                                                                {option?.amount}
                                                            </span>{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary btn-sm"
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                    <button
                        className="btn green btn-sm"
                        variant="primary"
                        onClick={addQuestionsToProduct}
                    >
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SelectQuestions;
