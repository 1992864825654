import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageUpload from "../../commonComponents/ImageUpload";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { BASE_URL, VALID_FILE_SIZE } from "../../utils/constant";
import { isValidFile } from "../../utils/Utils";
import SelectQuestions from "../questions/SelectQuestions";
import SortingQuestions from "../questions/SortingQuestions";
import SingleEditableQuestion from "../questions/SingleEditableQuestion";
import CreatableSelect from "react-select/creatable";

const Product = () => {
  const [productInfo, setProductInfo] = useState({});
  const [productDocumentColorMap, setProductDocumentColorMap] = useState([]);
  const [editableField, setEditableField] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [brand, setBrand] = useState(null);
  const [featureImage, setFeatureImage] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [productConditionList, setProductConditionList] = useState([]);
  const { id } = useParams();
  const [showSelectQestion, setShowSelectQestion] = useState(false);
  const [showSortQestion, setShowSortQestion] = useState(false);
  const [productQuestionList, setProductQuestionList] = useState([]);
  const [capacityVariantsList, setCapacityVariantsList] = useState([]);
  const [batteryVariantsList, setBatteryVariantsList] = useState([]);
  const [selectedTechnicalKey, setSelectedTechnicalKey] = useState([]);
  const [technicalDetailsList, setTechnicalDetailsList] = useState([]);

  const getProductInfo = () => {
    Http.GET("getProductInfo", id).then(({ data }) => {
      setProductInfo(data);
      setBatteryVariantsList(data.batteryTagResponseList);
      setCapacityVariantsList(data.capacityTagResponseList);
      setTechnicalDetailsList(data.technicalTagResponseList);
      let docomentColors = data.documentColorMapResponses;
      docomentColors = docomentColors.map((item) => {
        item["isEditShow"] = false;
        item["hexCode"] = item.colorResponse.hexCode;
        item["colorId"] = item.colorResponse.id;
        return item;
      });
      setProductDocumentColorMap(docomentColors);
      if (data.categoryType === "SELL" || data.categoryType === "REPAIR") {
        getProductQuestionList(data.categoryType);
      }
    });
  };

  const technicalKeyList = [
    {
      value: "Display",
      label: "Display",
    },
    {
      value: "Rear camera",
      label: "Rear camera",
    },
    {
      value: "Front camera",
      label: "Front camera",
    },
    {
      value: "Storage",
      label: "Storage",
    },
    {
      value: "processor",
      label: "Processor",
    },
    {
      value: "SIM card",
      label: "SIM card",
    },
    {
      value: "Bluetooth",
      label: "Bluetooth",
    },
  ];

  const getSelectedValue = (variant) => {
    if (variant.key) {
      return {
        label: variant?.key,
        value: variant?.key,
      };
    } else {
      return null;
    }
  };

  const changeTagElement = (type, fieldName, index, value) => {
    console.log("vvv", value);
    if (type === "PRODUCT_CAPACITY") {
      let capacityList = [...capacityVariantsList];
      capacityList[index][fieldName] = value;
      capacityList[index]["isEdit"] = true;
      setCapacityVariantsList(capacityList);
    } else if (type === "PRODUCT_BATTERY_CONDITION") {
      let batteryList = [...batteryVariantsList];
      batteryList[index][fieldName] = value;
      batteryList[index]["isEdit"] = true;
      setBatteryVariantsList(batteryList);
    } else if (type === "TECHNICAL_DETAILS") {
      let technicalList = [...technicalDetailsList];
      technicalList[index][fieldName] = value;
      technicalList[index]["isEdit"] = true;
      setTechnicalDetailsList(technicalList);
    }
  };

  const handleTagSave = (variant, index) => {
    const data = {
      ...variant,
      serial: index,
    };

    Http.POST("addTags", data)
      .then((response) => {
        const data = response.data.payload;

        if (data.type === "PRODUCT_CAPACITY") {
          let tempData = [...capacityVariantsList];
          tempData[index] = response.data.payload;
          setCapacityVariantsList(tempData);
        } else if (data.type === "PRODUCT_BATTERY_CONDITION") {
          let tempData = [...batteryVariantsList];
          tempData[index] = response.data.payload;
          setBatteryVariantsList(tempData);
        } else if (data.type === "TECHNICAL_DETAILS") {
          let tempData = [...technicalDetailsList];
          tempData[index] = response.data.payload;
          setTechnicalDetailsList(tempData);
        }

        toastSuccess("Successfully added");
      })
      .catch((error) => {
        toastError("Something went wrong");
      });
  };

  const handleAddTagVariant = (type) => {
    const newVariant = {
      text: "",
      amountType: "FLAT_DISCOUNT",
      amount: 0,
      type: type,
      productId: id,
      isEdit: true,
    };
    if (type === "PRODUCT_CAPACITY") {
      let capacityList = [...capacityVariantsList];
      capacityList.push(newVariant);
      setCapacityVariantsList(capacityList);
    } else if (type === "PRODUCT_BATTERY_CONDITION") {
      let batteryList = [...batteryVariantsList];
      batteryList.push(newVariant);
      setBatteryVariantsList(batteryList);
    } else if (type === "TECHNICAL_DETAILS") {
      let technicalList = [...technicalDetailsList];
      technicalList.push(newVariant);
      setTechnicalDetailsList(technicalList);
    }
  };

  const getBrandList = () => {
    Http.GET("brandList")
      .then((response) => {
        let data = response.data;
        setBrandList(data.data);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const getProductConditionList = () => {
    Http.GET("enumList", "/com.rebuild.swap.enums.ProductCondition", false)
      .then((response) => {
        let data = response.data;
        setProductConditionList(data);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const getColorList = () => {
    Http.GET("getColorList")
      .then((response) => {
        let data = response.data;
        setColorList(data);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const getProductQuestionList = () => {
    Http.GET("productQuestionList", id).then(({ data }) => {
      setProductQuestionList(data);
    });
  };

  const handleEditableFieldName = (fieldName) => {
    setEditableField(fieldName);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (fieldName) => {
    let postData = {
      [fieldName]: productInfo[fieldName],
    };
    try {
      await Http.PUT("setProductInfo", postData, id);
      setEditableField(null);
    } catch {
      toastError("Update failed!");
    }
  };

  const onFileUpload = (e) => {
    let file = e.target.files[0];
    if (isValidFile(file, "image", VALID_FILE_SIZE)) {
      let data = {
        name: file.name,
        docMimeType: file.type,
        documentType: "ACCESSORIES_DESIGN",
        base64Str: "",
        size: file.size,
      };
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        data.base64Str = reader.result;
        setFeatureImage(data);
        let postData = {
          documentDTO: data,
        };
        try {
          Http.PUT("setProductInfo", postData, id);
          setEditableField(null);
        } catch {
          toastError("Update failed!");
        }
      };
      reader.onerror = function (error) {
        toastError(error);
      };
    }
  };

  const setDocumentDto = (image, index) => {
    let documentMapList = [...productDocumentColorMap];
    documentMapList[index].documentDTO = image;
    setProductDocumentColorMap(documentMapList);
  };

  const setColorInfo = (id, index) => {
    let color = colorList.find((item) => item.id == id);
    let documentMapList = [...productDocumentColorMap];
    documentMapList[index].colorId = color.id;
    documentMapList[index].hexCode = color.hexCode;
    setProductDocumentColorMap(documentMapList);
  };

  const setColorPrice = (value, index) => {
    let documentMapList = [...productDocumentColorMap];
    documentMapList[index].price = value;
    setProductDocumentColorMap(documentMapList);
  };

  const makeEditable = (index) => {
    let documentMapList = [...productDocumentColorMap];
    documentMapList[index].isEditShow = true;
    setProductDocumentColorMap(documentMapList);
  };

  const addDocumentColorMap = () => {
    let documentMapList = [...productDocumentColorMap];
    let documentColorMap = {
      isEditShow: true,
      id: null,
      productId: id,
      documentResponse: {},
      colorResponse: {},
      documentDTO: {},
      colorId: "",
      price: 0,
    };
    documentMapList.push(documentColorMap);
    setProductDocumentColorMap(documentMapList);
  };

  const addDocumentColorMapInfo = (index) => {
    let tempInfo = productDocumentColorMap[index];

    const data = {
      id: tempInfo.id,
      productId: tempInfo.productId,
      colorId: tempInfo.colorId,
      documentDTO: tempInfo.documentDTO,
      price: tempInfo.price,
    };

    Http.POST(`${BASE_URL}/api/products/${id}/document-color`, data, "", true)
      .then((response) => {
        toastSuccess("Succuessfully added");
        let documentMapList = [...productDocumentColorMap];
        let data = response.data.payload;
        documentMapList[index].id = response.data.id;
        documentMapList[index].documentResponse = data.documentResponse;
        documentMapList[index].colorResponse = data.colorResponse;
        documentMapList[index].price = data.price;
        documentMapList[index].isEditShow = false;
        setProductDocumentColorMap(documentMapList);
      })
      .catch((error) => {
        toastError("Something went wrong");
      });
  };

  useEffect(() => {
    getProductInfo();
    getBrandList();
    getColorList();
    getProductConditionList();
  }, []);

  return (
    <div className="m-4">
      <div className="product-details bg-white p-4">
        <div className="first-row">
          <div className="feature-image">
            <img
              src={
                featureImage.base64Str
                  ? featureImage.base64Str
                  : productInfo?.featureImageResponse?.docUrl ||
                    "/img/default.png"
              }
              alt="feature"
            />
            <div className="reupload-image">
              <label
                htmlFor="reuploaBrand"
                className="drag-upload cursor-pointer mb-0 mr-2 pr-1"
              >
                <img
                  src="/icons/upload.svg"
                  alt="upload"
                  className="upload-icon"
                />
              </label>
              <input
                type="file"
                className="file-upload d-none"
                name="reuploaBrand"
                id="reuploaBrand"
                onClick={(e) => (e.target.value = null)}
                onChange={(e) => onFileUpload(e)}
              />
            </div>
          </div>
          <div className="details mt-5">
            <div className="catergory mb-5">
              <p>
                Category :
                <span className="weight-500">
                  {productInfo?.categoryResponse?.name}
                </span>
              </p>
            </div>
            <div className="price">
              <p className="d-flex align-items-center">
                Price :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("price")}
                >
                  {editableField !== "price" && (
                    <span>
                      ${productInfo?.price}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "price" && (
                    <input
                      autoFocus={true}
                      type="number"
                      name="price"
                      value={productInfo?.price}
                      onChange={handleChange}
                      onBlur={() => handleSave("price")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>

              <p className="d-flex align-items-center mt-5">
                Discount Percentage :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("discountPercentage")}
                >
                  {editableField !== "discountPercentage" && (
                    <span>
                      ${productInfo?.discountPercentage}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "discountPercentage" && (
                    <input
                      autoFocus={true}
                      type="number"
                      name="discountPercentage"
                      value={productInfo?.discountPercentage}
                      onChange={handleChange}
                      onBlur={() => handleSave("discountPercentage")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>
            </div>
          </div>

          <div className="details mt-5">
            <div className="brand mb-5">
              <p className="d-flex align-items-center">
                Brand Name :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("brandResponse")}
                >
                  {editableField !== "brandResponse" && (
                    <span>
                      {productInfo?.brandResponse?.name}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "brandResponse" && (
                    <select
                      name="brand "
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                      onBlur={() => handleSave("brandResponse")}
                      className="form-select form-select-sm ms-2"
                    >
                      <option>Select</option>
                      {brandList &&
                        brandList.map((item) => (
                          <option key={item?.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  )}
                </span>
              </p>
            </div>
            <div className="qantity mb-5">
              <p className="d-flex align-items-center">
                Qantity :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("availableQty")}
                >
                  {editableField !== "availableQty" && (
                    <span>
                      {productInfo?.availableQty}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "availableQty" && (
                    <input
                      autoFocus={true}
                      type="number"
                      name="availableQty"
                      value={productInfo?.availableQty}
                      onChange={handleChange}
                      onBlur={() => handleSave("availableQty")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>
            </div>
          </div>

          <div className="details mt-5">
            <div className="name mb-5">
              <p className="d-flex align-items-center">
                Product Name :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("name")}
                >
                  {editableField !== "name" && (
                    <span>
                      {productInfo?.name}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "name" && (
                    <input
                      autoFocus={true}
                      type="text"
                      name="name"
                      value={productInfo?.name}
                      onChange={handleChange}
                      onBlur={() => handleSave("name")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>
            </div>
          </div>

          <div className="details mt-5">
            <div className="brand mb-5">
              <p className="d-flex align-items-center">
                condition :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("condition")}
                >
                  {editableField !== "condition" && (
                    <span>
                      {" "}
                      {productInfo?.condition}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "condition" && (
                    <select
                      name="condition"
                      value={productInfo?.condition}
                      onChange={handleChange}
                      onBlur={() => handleSave("condition")}
                      className="form-select form-select-sm ms-2"
                    >
                      <option>Select</option>
                      {productConditionList &&
                        productConditionList.map((item) => (
                          <option key={item?.constant} value={item.constant}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  )}
                </span>
              </p>
            </div>
          </div>

          <div className="details mt-5">
            <div className="grade mb-5">
              <p className="d-flex align-items-center">
                Grade :{" "}
                <span
                  className="weight-500"
                  onClick={() => handleEditableFieldName("grade")}
                >
                  {editableField !== "grade" && (
                    <span>
                      {" "}
                      {productInfo?.grade}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "grade" && (
                    <select
                      name="grade"
                      value={productInfo?.grade}
                      onChange={handleChange}
                      onBlur={() => handleSave("grade")}
                      className="form-select form-select-sm ms-2"
                    >
                      <option>Select</option>
                      <option value="A"> A </option>
                      <option value="B"> B </option>
                      <option value="B"> C </option>
                    </select>
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="description">
          <div className="details mt-5">
            <div className="box-contain">
              <p className="d-flex align-items-center">
                <span className="whitespace-nowrap">Box Contain: </span>
                <span
                  className="weight-500 w-100"
                  onClick={() => handleEditableFieldName("boxContain")}
                >
                  {editableField !== "boxContain" && (
                    <span>
                      {productInfo?.boxContain}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "boxContain" && (
                    <textarea
                      autoFocus={true}
                      type="text"
                      name="boxContain"
                      value={productInfo?.boxContain}
                      onChange={handleChange}
                      onBlur={() => handleSave("boxContain")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="details mt-5">
            <div className="box-contain">
              <p className="d-flex align-items-center">
                Description:{" "}
                <span
                  className="weight-500 w-100"
                  onClick={() => handleEditableFieldName("description")}
                >
                  {editableField !== "description" && (
                    <span>
                      {productInfo?.description}
                      <img
                        src="/icons/edit-text.svg"
                        alt="edit"
                        className="edit-hover cursor-pointer ms-2"
                      />
                    </span>
                  )}
                  {editableField === "description" && (
                    <textarea
                      autoFocus={true}
                      type="text"
                      name="description"
                      value={productInfo?.description}
                      onChange={handleChange}
                      onBlur={() => handleSave("description")}
                      className="form-control form-control-sm ms-2"
                    />
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {productInfo?.categoryType === "BUY" ? (
        <>
          <div className="capacity-and-battery mt-4 bg-white p-4 d-flex">
            <div className="capacity w-50">
              <h5 className="mb-2">Capacity</h5>
              <div className="variants">
                {capacityVariantsList.map((variant, index) => (
                  <div className="single-variant" key={index}>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="optionTitle" className="text-sm mb-1">
                          Capacity
                        </label>
                        <select
                          name="capacity"
                          className="form-select form-select-sm"
                          value={variant.text}
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_CAPACITY",
                              "text",
                              index,
                              e.target.value
                            )
                          }
                        >
                          <option>Select</option>
                          <option value="64GB">64GB</option>
                          <option value="128GB">128GB</option>
                          <option value="256GB">256GB</option>
                          <option value="512GB">512GB</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="optionAmountType"
                          className="text-sm mb-1"
                        >
                          Amount type
                        </label>
                        <select
                          name="optionAmountType"
                          className="form-select form-select-sm"
                          value={variant.amountType}
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_CAPACITY",
                              "amountType",
                              index,
                              e.target.value
                            )
                          }
                        >
                          <option value="PERCENTAGE">Percentage</option>
                          <option value="FLAT_DISCOUNT">Flat Discount</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="optionAmount" className="text-sm mb-1">
                          Amount
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          value={variant.amount > 0 ? variant.amount : null}
                          placeholder="0"
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_CAPACITY",
                              "amount",
                              index,
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-2 mt-4">
                        {variant?.isEdit ? (
                          <button
                            type="submit"
                            className="btn green w-full btn-sm rounded-0 mt-3"
                            onClick={() => handleTagSave(variant, index)}
                          >
                            <span className=" me-1">Save</span>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="text-end">
                  <button
                    className="add-option bg-transparent"
                    onClick={() => handleAddTagVariant("PRODUCT_CAPACITY")}
                  >
                    <img src="/icons/plus-circle.svg" alt="add" />
                  </button>
                </div>
              </div>
            </div>
            <div className="battery w-50">
              <h5 className="mb-2">Battery</h5>
              <div className="variants">
                {batteryVariantsList.map((variant, index) => (
                  <div className="single-variant" key={index}>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="optionTitle" className="text-sm mb-1">
                          Battery Status
                        </label>
                        <select
                          name="batteryStatus"
                          className="form-select form-select-sm"
                          value={variant.text}
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_BATTERY_CONDITION",
                              "text",
                              index,
                              e.target.value
                            )
                          }
                        >
                          <option>Select</option>
                          <option value="Standard">Standard</option>
                          <option value="100% Battery">100% Battery</option>
                          <option value="80% - 90%">80% - 90%</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="optionAmountType"
                          className="text-sm mb-1"
                        >
                          Amount type
                        </label>
                        <select
                          name="optionAmountType"
                          className="form-select form-select-sm"
                          value={variant.amountType}
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_BATTERY_CONDITION",
                              "amountType",
                              index,
                              e.target.value
                            )
                          }
                        >
                          <option value="PERCENTAGE">Percentage</option>
                          <option value="FLAT_DISCOUNT">Flat Discount</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="optionAmount" className="text-sm mb-1">
                          Amount
                        </label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="0"
                          value={variant.amount > 0 ? variant.amount : null}
                          onChange={(e) =>
                            changeTagElement(
                              "PRODUCT_BATTERY_CONDITION",
                              "amount",
                              index,
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-2 mt-4">
                        {variant?.isEdit ? (
                          <button
                            type="submit"
                            className="btn green w-full btn-sm rounded-0 mt-3"
                            onClick={() => handleTagSave(variant, index)}
                          >
                            <span className="me-1">Save </span>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="text-end">
                  <button
                    className="add-option bg-transparent"
                    onClick={() =>
                      handleAddTagVariant("PRODUCT_BATTERY_CONDITION")
                    }
                  >
                    <img src="/icons/plus-circle.svg" alt="add" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="technical-details mt-4 bg-white p-4">
            <h5 className="mb-2">Technical details</h5>
            <div className="variants">
              {technicalDetailsList.map((variant, index) => (
                <div className="single-variant mb-3" key={index}>
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        htmlFor={`optionKey-${index}`}
                        className="text-sm mb-1"
                      >
                        Key
                      </label>
                      <CreatableSelect
                        id={`optionKey-${index}`}
                        value={getSelectedValue(variant)}
                        onChange={(e) =>
                          changeTagElement(
                            "TECHNICAL_DETAILS",
                            "key",
                            index,
                            e.value
                          )
                        }
                        options={technicalKeyList}
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor={`optionText-${index}`}
                        className="text-sm mb-1"
                      >
                        Text
                      </label>
                      <input
                        type="text"
                        id={`optionText-${index}`}
                        className="form-control form-control-sm"
                        onChange={(e) =>
                          changeTagElement(
                            "TECHNICAL_DETAILS",
                            "text",
                            index,
                            e.target.value
                          )
                        }
                        value={variant.text}
                      />
                    </div>

                    <div className="col-md-2 mt-4">
                      {variant?.isEdit ? (
                        <button
                          type="submit"
                          className="btn green w-full btn-sm rounded-0 mt-3"
                          onClick={() => handleTagSave(variant, index)}
                        >
                          <span className=" me-1">Save</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-end">
                <button
                  className="add-option bg-transparent"
                  onClick={() => handleAddTagVariant("TECHNICAL_DETAILS")}
                >
                  <img src="/icons/plus-circle.svg" alt="add" />
                </button>
              </div>
            </div>
          </div>
          <div className="product-varients mt-4 bg-white p-4">
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Color</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productDocumentColorMap &&
                    productDocumentColorMap.map((item, index) => (
                      <tr key={"document_color_" + index}>
                        <td>
                          <div className="d-flex">
                            <img
                              src={
                                item?.documentResponse?.docUrl ||
                                "/img/default.png"
                              }
                              alt="feature"
                            />
                            {item?.isEditShow ? (
                              <ImageUpload
                                onUploadImage={(image) =>
                                  setDocumentDto(image, index)
                                }
                                docType={"PRODUCT_DESIGN"}
                                type={"image"}
                                isEditable={false}
                                size={"sm"}
                                isUploadMulti={false}
                              />
                            ) : (
                              <img
                                className="uploaded-logo border-0 ms-3"
                                src={
                                  productImage?.base64Str
                                    ? productImage?.base64Str
                                    : ""
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              className="color-icon"
                              style={{
                                backgroundColor: `${item?.hexCode}`,
                              }}
                            />
                            {item?.isEditShow && (
                              <select
                                name="color"
                                value={item.colorId}
                                onChange={(e) =>
                                  setColorInfo(e.target.value, index)
                                }
                                className="form-select form-select-sm ms-2"
                              >
                                <option>Select</option>
                                {colorList &&
                                  colorList.map((item) => (
                                    <option
                                      key={"color_" + index + "_" + item?.id}
                                      value={item?.id}
                                    >
                                      {item?.name}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            {item?.isEditShow !== true && item?.price && (
                              <span className="color-price">{item.price}</span>
                            )}
                            {item?.isEditShow && (
                              <input
                                autoFocus={true}
                                type="text"
                                name="price"
                                value={item?.price}
                                onChange={(e) =>
                                  setColorPrice(e.target.value, index)
                                }
                                className="form-control form-control-sm ms-2"
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          {item.isEditShow ? (
                            <button
                              type="submit"
                              className="btn green btn-sm rounded-0"
                              onClick={() => addDocumentColorMapInfo(index)}
                            >
                              <span className=" me-1">
                                {" "}
                                {item.id === null ? "Submit" : "Update"}{" "}
                              </span>
                            </button>
                          ) : (
                            <span
                              className="cursor-pointer"
                              onClick={() => makeEditable(index)}
                            >
                              <img src="/icons/edit.svg" alt="edit" />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end mt-2">
                <button
                  type="submit"
                  className="btn green btn-sm rounded-0"
                  onClick={() => addDocumentColorMap()}
                >
                  <span className=" me-1">Add</span>
                </button>
              </div>
              {productInfo?.documentColorMapResponses?.length === 0 && (
                <p className="empty-state">No variations found.</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="product-questions bg-white p-4 mt-4">
          <div className="d-flex justify-content-end">
            <button
              className="add-option mb-2 me-3"
              onClick={() => setShowSortQestion(true)}
            >
              <img src="/icons/list.svg" alt="list" />
            </button>
            <button
              className="add-option mb-2"
              onClick={() => setShowSelectQestion(true)}
            >
              <img src="/icons/plus-circle.svg" alt="add" />
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Questions</th>
                </tr>
              </thead>
              <tbody>
                {productQuestionList &&
                  productQuestionList.map((item, index) => (
                    <tr key={"product_question_" + item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <SingleEditableQuestion questionData={item} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <SelectQuestions
        showSelectQestion={showSelectQestion}
        setShowSelectQestion={setShowSelectQestion}
        product={productInfo}
        productQuestionList={productQuestionList}
      />
      <SortingQuestions
        showSortQestion={showSortQestion}
        setShowSortQestion={setShowSortQestion}
        productId={id}
        productQuestionList={productQuestionList}
      />
    </div>
  );
};

export default Product;
