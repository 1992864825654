import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../commonComponents/ImageUpload";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

function EditBrand() {
    const [name, setName] = useState("");
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState({});
    const [oldLogo, setOldLogo] = useState({});
    const [showCustomerPanel, setShowCustomerPanel] = useState(false);
    const { id } = useParams();
    const [error, setError] = useState({});
    const navigate = useNavigate();

    const getBrandInfo = () => {
        Http.GET("getBrandInfo", id).then(({ data }) => {
            setName(data.name);
            setDescription(data.description);
            setOldLogo(data.brandLogoDocResponse);
            setWebsite(data.website);
            setShowCustomerPanel(data.isShowCustomer);
        });
    };
    const onUploadImage = (image) => {
        setLogo({
            logoDocumentDTO: image,
        });
    };

    const validate = () => {
        const errors = {};

        if (!name) {
            errors.name = "Name is required.";
        }
        if (Object.keys(oldLogo).length === 0) {
            errors.logo = "Logo is required.";
        }
        setError(errors);
        return Object.keys(errors).length === 0;
    };

    const editBrand = (e) => {
        if (validate()) {
            const data = {
                id: id,
                name: name,
                website: website,
                description: description,
                logoDocumentDTO: logo.logoDocumentDTO,
                isShowCustomer: showCustomerPanel,
            };

            Http.PUT("editBrand", data)
                .then((response) => {
                    toastSuccess("Brand succuessfully updated");
                    navigate("/brand");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        getBrandInfo();
    }, []);

    return (
        <div className="p-5">
            <div className="form-container">
                <h3 className="text-md mb-2">Edit Brand</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <ImageUpload
                                    onUploadImage={onUploadImage}
                                    docType={"PROFILE_PHOTO"}
                                    type={"image"}
                                    isEditable={false}
                                    size={"sm"}
                                />
                                {error.logo && <p className="text-danger text-sm">{error.logo}</p>}
                            </div>
                            <div className="col-md-9">
                                <img
                                    className="uploaded-logo border-0"
                                    src={
                                        logo?.logoDocumentDTO?.base64Str
                                            ? logo?.logoDocumentDTO?.base64Str
                                            : oldLogo?.docUrl
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="name" className="text-sm mb-1">
                                    Brand Name*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={name}
                                    name="name"
                                    placeholder="Brand name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {error.name && <p className="text-danger text-sm">{error.name}</p>}
                            </div>

                            <div className="col-md-6 mt-2">
                                <label htmlFor="website" className="text-sm mb-1">
                                    Website
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={website}
                                    name="website"
                                    placeholder="website"
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Description
                                </label>

                                <textarea
                                    className="form-control form-control-sm"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        defaultChecked={showCustomerPanel}
                                        className="form-check-input"
                                        checked={showCustomerPanel}
                                        onChange={(e) => setShowCustomerPanel(e.target.checked)}
                                        id="showCustomerPanel"
                                    />
                                    <label className="form-check-label" htmlFor="showCustomerPanel">
                                        Show on customer panel
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={editBrand}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBrand;
