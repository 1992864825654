import React, { useState, useEffect } from "react";
import ImageUpload from "../../commonComponents/ImageUpload";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

function EditCatergory() {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [type, setType] = useState("");
    const [isParent, setIsParent] = useState(false);
    const [parentCategory, setParentCategory] = useState(null);
    const [categoryImage, setCategoryImage] = useState({});
    const [oldLogo, setOldLogo] = useState({});
    const [categoryList, setcategoryList] = useState([]);
    const [showCustomerPanel, setShowCustomerPanel] = useState(false);
    const [isShowCompare, setIsShowCompare] = useState(false);
    const [error, setError] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    const validate = () => {
        const errors = {};

        if (!name) {
            errors.name = "Name is required.";
        }

        if (Object.keys(categoryImage).length === 0) {
            errors.logo = "Image is required.";
        }

        setError(errors);

        return Object.keys(errors).length === 0;
    };

    const getCategoryList = (type) => {
        Http.GET("categoryList", `?type=${type}&page=0&size=1000`)
            .then((response) => {
                let data = response.data;
                setcategoryList(data.data);
            })
            .catch((response) => {
                toastError("Something went wrong!");
            });
    };

    const typeChange = (type) => {
        setType(type);
        getCategoryList(type);
    };

    const getCategoryInfo = () => {
        Http.GET("getCategoryInfo", id).then(({ data }) => {
            setOldLogo(data.logo);
            setName(data.name);
            setCode(data.code);
            setCategoryImage(data.logo);
            setType(data.type);
            setParentCategory(data.parentId);
            setShowCustomerPanel(data.isShowCustomer);
            setIsShowCompare(data.isShowCompare);
            {
                data.parentId && data.parentId > 0 && setIsParent(true);
            }
        });
    };

    const onUploadImage = (image) => {
        setCategoryImage({
            logoDocumentDTO: image,
        });
    };

    const handleSubmit = (e) => {
        if (validate()) {
            const data = {
                name: name,
                code: code,
                type: type,
                parentId: parseInt(parentCategory),
                logoDocumentDTO: categoryImage.logoDocumentDTO,
                isShowCustomer: showCustomerPanel,
                isShowCompare: isShowCompare,
            };

            Http.PUT("editCategory", data, id)
                .then((response) => {
                    toastSuccess("Succuessfully updated");
                    navigate("/categories");
                })
                .catch((error) => {
                    toastError("Something went wrong");
                });
        }
    };

    useEffect(() => {
        getCategoryInfo();
        getCategoryList("");
    }, []);

    return (
        <div className="p-5">
            <div className="form-container">
                <h3 className="text-md mb-2">Edit Catergory</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <ImageUpload
                                    onUploadImage={onUploadImage}
                                    docType={"PROFILE_PHOTO"}
                                    type={"image"}
                                    isEditable={false}
                                    size={"sm"}
                                />
                                {error.logo && <p className="text-danger text-sm">{error.logo}</p>}
                            </div>
                            <div className="col-md-9">
                                <img
                                    className="uploaded-logo border-0"
                                    src={
                                        categoryImage?.logoDocumentDTO?.base64Str
                                            ? categoryImage?.logoDocumentDTO?.base64Str
                                            : oldLogo?.docUrl
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="name" className="text-sm mb-1">
                                    Catergory Name*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={name}
                                    name="name"
                                    placeholder="Catergory name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {error.name && <p className="text-danger text-sm">{error.name}</p>}
                            </div>

                            <div className="col-md-6 mt-2">
                                <label htmlFor="website" className="text-sm mb-1">
                                    Code
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={code}
                                    name="website"
                                    placeholder="Code"
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Type
                                </label>

                                <select
                                    name="status"
                                    value={type}
                                    onChange={(e) => typeChange(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="BUY">Buy</option>
                                    <option value="SELL">Sell</option>
                                    <option value="REPAIR">Repair</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Is Parent
                                </label>

                                <select
                                    name="isParent"
                                    value={isParent}
                                    onChange={(e) => setIsParent(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option value={false}>Yes</option>
                                    <option value={true}>No</option>
                                </select>
                            </div>
                            {isParent && (
                                <div className="col-md-12 mt-4">
                                    <label htmlFor="linkedIn" className="text-sm mb-1">
                                        Parent Catergory
                                    </label>

                                    <select
                                        name="parentCategory"
                                        value={parentCategory}
                                        onChange={(e) => setParentCategory(e.target.value)}
                                        className="form-select form-select-sm"
                                    >
                                        <option>Select</option>
                                        {categoryList &&
                                            categoryList.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                    </select>
                                </div>
                            )}
                            <div className="col-md-6 mt-4">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        defaultChecked={showCustomerPanel}
                                        className="form-check-input"
                                        checked={showCustomerPanel}
                                        onChange={(e) => setShowCustomerPanel(e.target.checked)}
                                        id="showCustomerPanel"
                                    />
                                    <label className="form-check-label" htmlFor="showCustomerPanel">
                                        Show on customer panel
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6 mt-4">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        defaultChecked={ isShowCompare }
                                        className="form-check-input"
                                        checked={ isShowCompare }
                                        onChange={(e) => setIsShowCompare( e.target.checked )}
                                        id="isShowCompare"
                                    />
                                    <label className="form-check-label" htmlFor="isShowCompare">
                                        Show on Compare
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditCatergory;
