import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { BASE_URL } from "../../utils/constant";

const SingleEditableQuestion = ({ questionData }) => {
    const navigate = useNavigate();
    const [question, setQuestion] = useState(questionData);
    console.log( question );

    const handleChangeQuestion = (fieldName, e) => {
        let tempQuestion = { ...question };
        tempQuestion[fieldName] = e.target.value;
        setQuestion(tempQuestion);
    };
    const handleSaveQuestion = async (fieldName) => {
        let postData = {
            [fieldName]: question[fieldName],
        };
        try {
            await Http.PUT("updateQuestion", postData, question.id);
            toastSuccess("Successfully updated");
        } catch {
            toastError("Update failed!");
        }
    };

    const handleChangeQuestionOption = (fieldName, optionId, e) => {
        let tempQuestion = { ...question };
        tempQuestion.optionResponseList = tempQuestion.optionResponseList.map((item) => {
            if (item.id === optionId) {
                item[fieldName] = e.target.value;
            }
            return item;
        });
        setQuestion(tempQuestion);
    };
    const handleSaveQuestionOptions = async (fieldName, optionId) => {
        let option = question.optionResponseList.filter((item) => item.id === optionId)[0];

        let postData = {
            [fieldName]: option[fieldName],
        };
        try {
            await Http.PUT(
                `${BASE_URL}/api/question/${question.id}/options/${optionId}`,
                postData,
                "",
                true
            );
            toastSuccess("Successfully updated");
        } catch {
            toastError("Update failed!");
        }
    };

    return (
        <div className="py-2 single-question">
            <p className="regular-14 dark weight-500 mb-2">
                <input
                    type="text"
                    name="question-title"
                    value={question?.title}
                    onChange={(e) => handleChangeQuestion("title", e)}
                    onBlur={(e) => handleSaveQuestion("title")}
                    className="form-control form-control-sm question-title"
                />
            </p>

            <div className="types mt-1 ms-4 mb-2">
                <p className="regular-12 pb-1">
                    Amount Type: <span className="dark">{question?.amountType}</span>{" "}
                </p>
                <p className="regular-12">
                    Amount:{" "}
                    <span className="dark">
                        <input
                            type="number"
                            name="question-title"
                            value={question?.amount}
                            onChange={(e) => {
                                const inputValue = parseInt(e.target.value);
                                if ( !isNaN(inputValue) && inputValue >= 0  ) {
                                    handleChangeQuestion("amount", e);
                                }
                            }}
                            onBlur={(e) => handleSaveQuestion("amount")}
                            className="form-control form-control-sm mt-2 amount"
                        />
                    </span>{" "}
                </p>
            </div>
            <div className="options mt-3 ms-6">
                <p className="regular-12 weight-500 dark mb-2">Options</p>
                <div className="d-flex flex-wrap">
                    {question?.optionResponseList &&
                        question.optionResponseList.map((option, optionIndex) => (
                            <div className="single-option me-10 mb-3" key={"option_" + option.id}>
                                <p className="regular-12 dark pb-1">
                                    {optionIndex + 1}.{" "}
                                    <input
                                        type="text"
                                        name="option-title"
                                        value={option?.title}
                                        onChange={(e) =>
                                            handleChangeQuestionOption("title", option.id, e)
                                        }
                                        onBlur={(e) =>
                                            handleSaveQuestionOptions("title", option.id)
                                        }
                                        className="form-control form-control-sm my-1"
                                    />
                                </p>
                                <div className="option-values">
                                    <p className="regular-12 pb-2">
                                        Amount Type:{" "}
                                        <select
                                            name="optionAmountType"
                                            value={option.amountType}
                                            onChange={(e) =>
                                                handleChangeQuestionOption(
                                                    "amountType",
                                                    option.id,
                                                    e
                                                )
                                            }
                                            onBlur={(e) =>
                                                handleSaveQuestionOptions("amountType", option.id)
                                            }
                                            className="form-select form-select-sm"
                                        >
                                            <option>Select</option>
                                            <option value="PERCENTAGE">Percentage</option>
                                            <option value="FLAT_DISCOUNT">Flat Discount</option>
                                        </select>
                                    </p>
                                    <p className="regular-12">
                                        Amount:{" "}
                                        <span className="dark">
                                            <input
                                                type="number"
                                                name="option-amount"
                                                value={option?.amount}
                                                onChange={(e) => {
                                                    const inputValue = parseInt(e.target.value);
                                                    if ( !isNaN(inputValue) && inputValue >= 0  ) {
                                                        handleChangeQuestionOption("amount", option.id, e);
                                                    }
                                                } }
                                                onBlur={(e) =>
                                                    handleSaveQuestionOptions("amount", option.id)
                                                }
                                                className="form-control form-control-sm mt-1"
                                            />
                                        </span>{" "}
                                    </p>
                                    { question?.categoryType === 'REPAIR' && (
                                        <p className="regular-12">
                                            Require Time(minute):{" "}
                                            <span className="dark">
                                            <input
                                                type="number"
                                                name="option-requireMinute"
                                                value={option?.requireMinute}
                                                onChange={(e) =>
                                                    handleChangeQuestionOption(
                                                        "requireMinute",
                                                        option.id,
                                                        e
                                                    )
                                                }
                                                onBlur={(e) =>
                                                    handleSaveQuestionOptions("requireMinute", option.id)
                                                }
                                                className="form-control form-control-sm mt-1"
                                            />
                                        </span>{" "}
                                        </p>
                                    ) }


                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SingleEditableQuestion;
