import React, { useRef } from 'react';
import { toastError, toastWarning } from '../utils/Toast';
import { isValidFile, sizeInMB, IMAGE_EXTS, PDF_EXTS } from '../utils/Utils';
import { VALID_FILE_SIZE } from '../utils/constant';

const ImageUpload = ({
	docType,
	documentGroup,
	onUploadImage,
	isEditable,
	type,
	size,
	isUploadMulti = false,
	imageSize = VALID_FILE_SIZE,
	uploadIcon = '/icons/upload-dark.svg'
}) => {
	const inputRef = useRef(null);
	const sm = 'file-uploader small upload-design-image mb-4 mb-sm-0';
	const lg = 'file-uploader large upload-design-image mt-3 mb-4 mb-sm-0';
	const xl = 'file-uploader full upload-design-image mt-3 mb-4 mb-sm-0';

	const commonFileTypes =
		'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*';
	const allDocType = '*';

	const acceptFileType = (fileType) => {
		switch (fileType) {
			case 'image':
				return 'image/*';
			case 'imageDocument':
				return commonFileTypes;
			case 'allDocType':
				return allDocType;
			case 'imageAndPdf':
				return 'application/pdf, image/*';
			default:
				return commonFileTypes;
		}
	};

	const onFileUpload = (e, docType) => {
		let file = e.target.files[0];
		if (isValidFile(file, type, imageSize)) {
			let data = {
				name: file.name,
				docMimeType: file.type,
				documentGroup,
				documentType: docType,
				base64Str: '',
				size: file.size
			};
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				data.base64Str = reader.result;
				onUploadImage(data);
			};
			reader.onerror = function (error) {
				toastError(error);
			};
		}
	};

	const onUploaderClick = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.click();
		}
	};

	const onMultipleFileUpload = (e, docType) => {
		let files = [];
		let modifiedfileList = [];
		files = [...e.target.files];
		if (files.length !== 0) {
			files.forEach((file, idx) => {
				if (file) {
					if (isValidFile(file, type, imageSize)) {
						let data = {
							name: file?.name,
							docMimeType: file?.type,
							documentType: docType,
							base64Str: ''
						};
						let reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => {
							data.base64Str = reader.result;
							modifiedfileList.push(data);
						};
						reader.onerror = function (error) {
							toastError(error);
						};
					}
				}
			});
			setTimeout(() => {
				if (modifiedfileList?.length > 0) {
					onUploadImage(modifiedfileList);
				}
			}, 100);
		}
	};

	return (
		<div className={`${size}` === 'lg' ? lg : `${size}` === 'xl' ? xl : sm} onClick={onUploaderClick}>
			{isEditable === true ? (
				<>
					<div className="reupload-btn">
						<img src={uploadIcon} />
					</div>
					<input
						ref={inputRef}
						type="file"
						className="file-upload"
						name="image"
						onClick={(e) => (e.target.value = null)}
						onChange={(e) => onFileUpload(e, docType)}
						accept={acceptFileType(type)}
						multiple={false}
					/>
				</>
			) : (
				<>
					<label htmlFor="drag-upload" className="drag-upload" />
					<input
						ref={inputRef}
						type="file"
						className="file-upload"
						name="image"
						onClick={(e) => (e.target.value = null)}
						onChange={(e) => {
							if (isUploadMulti) {
								onMultipleFileUpload(e, docType);
							} else onFileUpload(e, docType);
						}}
						accept={acceptFileType(type)}
						multiple={isUploadMulti}
					/>
				</>
			)}
		</div>
	);
};

export default ImageUpload;
