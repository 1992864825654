import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError } from "../../utils/Toast";
import SingleEditableQuestion from "./SingleEditableQuestion";

const QuestionList = () => {
  const [questionList, setQuestionList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginatedData, setPaginatedData] = useState({});
  const [categoryType, setCategoryType] = useState(null);
  const navigate = useNavigate();
  const size = 5;

  const getQuestionList = () => {
    setLoading(true);
    let param = `?page=${page}&size=${size}&search=${searchTerm}`;
    if (categoryType != null && categoryType != "")
      param += "&category_type=" + categoryType;
    Http.GET("questionList", param)
      .then((response) => {
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...questionList, ...data.data];
        }
        setQuestionList(data.data);

        setLoading(false);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getQuestionList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getQuestionList();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    getQuestionList();
  }, [page, categoryType]);

  const addQuestion = () => {
    navigate("/add-question");
  };

  return (
    <LoaderComponent loading={loading}>
      <div className="p-5 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {questionList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>

          <div className="categorie-type">
            <select
              name="cType"
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              className="form-select form-select-sm"
            >
              <option value="">Category Type</option>
              <option value="SELL">Sell</option>
              <option value="REPAIR">Repair</option>
            </select>
          </div>

          <button
            type="submit"
            className="btn green btn-sm rounded-0"
            onClick={addQuestion}
          >
            <span className="hide-mobile me-1">Add Question</span>
            <img src="/icons/plus.svg" alt="add" />
          </button>
        </div>
        <div
          className="scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered admin-list-table table-hover">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Category Type</th>
                </tr>
              </thead>
              <tbody>
                {questionList &&
                  questionList.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        {index + 1}.
                        <SingleEditableQuestion
                          questionData={item}
                        ></SingleEditableQuestion>
                      </td>
                      <td>
                        <span className="text-capitalize">
                          {item?.categoryType}
                        </span>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {questionList.length === 0 && (
              <p className="empty-state">No question found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default QuestionList;
