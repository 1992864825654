import React, { useEffect, useState } from "react";
import Http from "../../utils/Http";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError } from "../../utils/Toast";

const OrderList = () => {
  const [orderList, setOrderList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginatedData, setPaginatedData] = useState({});
  const size = 20;

  const getOrderList = () => {
    setLoading(true);
    Http.GET(
      "getOrderList",
      `?page=${page}&size=${size}&sort=id,desc&search=${searchTerm}`
    )
      .then((response) => {
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...orderList, ...data.data];
        }
        setOrderList(data.data);
        setLoading(false);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getOrderList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getOrderList();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    getOrderList();
  }, [page]);

  return (
    <LoaderComponent loading={loading}>
      <div className="p-5 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {orderList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>
        </div>
        <div
          className="scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered admin-list-table table-hover">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Invoice Id</th>
                  <th>Client Name</th>
                  <th>Phone</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orderList &&
                  orderList.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.invoiceNo}</td>
                      <td>{item?.clientName}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.amount.toFixed(2)}</td>
                      <td>{item?.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {orderList.length === 0 && (
              <p className="empty-state">No Order found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};
export default OrderList;
