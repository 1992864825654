import React from "react";

const AddCustomer = () => {
    return (
        <div className="p-5">
            <div className="bg-white form-container">
                <div className="container bg-white shadow p-5 bg-body rounded">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-md mb-2">Add Customer</h3>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="name" className="text-sm mb-1">
                                Name
                            </label>
                            <input
                                class="form-control form-control-sm"
                                type="text"
                                value=""
                                name=""
                                placeholder="Admin name"
                            ></input>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="name" className="text-sm mb-1">
                                Role
                            </label>
                            <select
                                class="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                            >
                                <option selected>Select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>

                        <div className="col-md-12 mt-4">
                            <label htmlFor="email" className="text-sm mb-1">
                                Email
                            </label>
                            <input
                                class="form-control form-control-sm"
                                type="email"
                                value=""
                                name=""
                                placeholder="Email"
                            ></input>
                        </div>

                        <div className="col-md-12 mt-4">
                            <label htmlFor="name" className="text-sm mb-1">
                                Status
                            </label>
                            <select
                                class="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                            >
                                <option value="active" selected>
                                    Active
                                </option>
                                <option value="disable">Disable</option>
                            </select>
                        </div>

                        <div className="col-md-12 mt-4">
                            <div className="text-end">
                                <button type="submit" className="btn green btn-sm rounded-0">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCustomer;
