import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

const AddAQuestion = () => {
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [categoryType, setCategoryType] = useState("");
    const [amountType, setAmountType] = useState("");
    const [amount, setAmount] = useState("");
    const [options, setOptions] = useState([{ title: "", amountType: "", amount: "" }]);
    const navigate = useNavigate();

    const typeChange = (type) => {
        setType(type);
    };

    const handleOptionChange = (index, field, value) => {
        const updatedOptions = [...options];
        updatedOptions[index][field] = value;
        setOptions(updatedOptions);
    };

    const handleAddOption = () => {
        setOptions([...options, { title: "", amountType: "", amount: "" }]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            title,
            type,
            categoryType,
            amountType,
            amount,
            optionAddRequestList: options,
        };

        Http.POST("addQuestion", data)
            .then((response) => {
                toastSuccess("Successfully added");
                navigate("/question-bank");
            })
            .catch((error) => {
                toastError("Something went wrong");
            });
    };

    return (
        <div className="p-5">
            <div className="form-container question-form-container">
                <h3 className="text-md mb-2">Add Question</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="title" className="text-sm mb-1">
                                    Title*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={title}
                                    name="title"
                                    placeholder="Question title"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="type" className="text-sm mb-1">
                                    Selection Type
                                </label>

                                <select
                                    name="type"
                                    value={type}
                                    onChange={(e) => typeChange(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="SINGLE_SELECTION">Single Selection</option>
                                    <option value="MULTIPLE_SELECTION">Multiple Selection</option>
                                </select>
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="categoryType" className="text-sm mb-1">
                                    Category Type
                                </label>

                                <select
                                    name="categoryType"
                                    value={categoryType}
                                    onChange={(e) => setCategoryType(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="SELL">Sell</option>
                                    <option value="REPAIR">Repair</option>
                                </select>
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="amountType" className="text-sm mb-1">
                                    Amount Type
                                </label>

                                <select
                                    name="amountType"
                                    value={amountType}
                                    onChange={(e) => setAmountType(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="PERCENTAGE">Percentage</option>
                                    <option value="FLAT">Flat Discount</option>
                                </select>
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="amount" className="text-sm mb-1">
                                    Amount
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={amount}
                                    name="amount"
                                    placeholder="Amount"
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12 my-6 options">
                                <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                    <h3 className="text-sm mb-2">Add option</h3>
                                    <button className="add-option" onClick={handleAddOption}>
                                        <img src="/icons/plus-circle.svg" alt="add" />
                                    </button>
                                </div>
                                {options.map((option, index) => (
                                    <div className="single-option" key={index}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="optionTitle"
                                                    className="text-sm mb-1"
                                                >
                                                    Option title*
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    value={option.title}
                                                    name="optionTitle"
                                                    placeholder="Option title"
                                                    onChange={(e) =>
                                                        handleOptionChange(
                                                            index,
                                                            "title",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                <label
                                                    htmlFor="optionAmountType"
                                                    className="text-sm mb-1"
                                                >
                                                    Amount type
                                                </label>

                                                <select
                                                    name="optionAmountType"
                                                    value={option.amountType}
                                                    onChange={(e) =>
                                                        handleOptionChange(
                                                            index,
                                                            "amountType",
                                                            e.target.value
                                                        )
                                                    }
                                                    className="form-select form-select-sm"
                                                >
                                                    <option>Select</option>
                                                    <option value="PERCENTAGE">Percentage</option>
                                                    <option value="FLAT">Flat Discount</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                <label
                                                    htmlFor="optionAmount"
                                                    className="text-sm mb-1"
                                                >
                                                    Amount
                                                </label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    value={option.amount}
                                                    name="optionAmount"
                                                    placeholder="Amount"
                                                    onChange={(e) =>
                                                        handleOptionChange(
                                                            index,
                                                            "amount",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAQuestion;
