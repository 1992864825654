import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError, toastSuccess } from "../../utils/Toast";
import Modal from "react-bootstrap/Modal";

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [retypePassword, setretypePassword] = useState("");
  const [error, setError] = useState({});
  const [idToEdit, setIdToEdit] = useState(null);
  const [paginatedData, setPaginatedData] = useState({});
  const size = 20;

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const addAdmin = () => {
    navigate("/addadmin");
  };

  const getAdminList = () => {
    setLoading(true);
    Http.GET("adminList", `?page=${page}&size=${size}&name=${searchTerm}`)
      .then((response) => {
        setLoading(false);
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...adminList, ...data.data];
        }
        setAdminList(data.data);
      })
      .catch((response) => {
        setLoading(false);
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getAdminList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getAdminList();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    getAdminList();
  }, [page]);

  const validate = () => {
    const errors = {};

    if (!password) {
      errors.password = "Password is required.";
    }

    if (!retypePassword) {
      errors.retypePassword = "Please retype password.";
    }

    setError(errors);

    return Object.keys(errors).length === 0;
  };

  const getId = (id) => {
    setIdToEdit(id);
    setShow(true);
  };

  const updatePassword = () => {
    const id = idToEdit;
    if (validate()) {
      const data = {
        password,
        retypePassword,
      };
      Http.PUT("updatePassword", data, id)
        .then((response) => {
          toastSuccess("Password updated successfully");
          setShow(false);
          setPassword("");
          setretypePassword("");
        })
        .catch(({ response }) => {
          toastError(response.data.message);
        });
    }
  };

  return (
    <LoaderComponent loading={loading}>
      <div className="p-5 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {adminList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="btn green btn-sm rounded-0"
            onClick={addAdmin}
          >
            <span className="hide-mobile me-1">Add Admin</span>
            <img src="/icons/plus.svg" alt="add" />
          </button>
        </div>
        <div
          className="scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered admin-list-table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Created by</th>
                  <th>Ceated at</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {adminList &&
                  adminList.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.status}</td>
                      <td>
                        <span className="text-capitalize">
                          {item?.createdBy?.nickName}
                        </span>{" "}
                      </td>
                      <td>{item?.createdAt}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span
                            className="cursor-pointer"
                            onClick={() => navigate("/admins/edit/" + item.id)}
                          >
                            <img src="/icons/edit.svg" alt="edit" />
                          </span>
                          <span
                            className="cursor-pointer ms-4 d-inline-block"
                            onClick={() => getId(item.id)}
                          >
                            <img src="/icons/key.svg" alt="edit" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {adminList.length === 0 && (
              <p className="empty-state">No admin found.</p>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p>Updatae password</p>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="password" className="text-sm mb-1">
                    Password
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="password"
                    value={password}
                    name="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error.password && (
                    <p className="text-danger text-sm">{error.password}</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="retypePassword" className="text-sm mb-1">
                    Confirm Password
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="password"
                    value={retypePassword}
                    name="retypePassword"
                    placeholder="Confirm password"
                    onChange={(e) => setretypePassword(e.target.value)}
                  />
                  {error.retypePassword && (
                    <p className="text-danger text-sm">
                      {error.retypePassword}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn green btn-sm"
            variant="primary"
            onClick={() => updatePassword()}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </LoaderComponent>
  );
};

export default AdminList;
