import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import LoaderComponent from "../../utils/LoaderComponent";

const BrandList = () => {
  const [brandList, setBrandList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginatedData, setPaginatedData] = useState({});
  const navigate = useNavigate();
  const size = 20;

  const getBrandList = () => {
    setLoading(true);
    Http.GET("brandList", `?page=${page}&size=${size}&name=${searchTerm}`)
      .then((response) => {
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...brandList, ...data.data];
        }
        setBrandList(data.data);

        setLoading(false);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getBrandList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getBrandList();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    getBrandList();
  }, [page]);

  const AddBrand = () => {
    navigate("/brands/add");
  };
  return (
    <LoaderComponent loading={loading}>
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {brandList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="btn green btn-sm rounded-0"
            onClick={AddBrand}
          >
            <span className="hide-mobile me-1">Add Brand</span>
            <img src="/icons/plus.svg" alt="add" />
          </button>
        </div>

        <div
          className="table-responsive scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Website</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {brandList &&
                  brandList.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img
                          src={item?.brandLogoDocResponse?.docUrl}
                          alt="Logo"
                          className="logo"
                        />
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.website}</td>
                      <td>{item?.description}</td>

                      <td>
                        <span
                          className="cursor-pointer"
                          onClick={() => navigate("/brands/edit/" + item.id)}
                        >
                          <img src="/icons/edit.svg" alt="edit" />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {brandList.length === 0 && (
              <p className="empty-state">No brand found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default BrandList;
