import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

const AddFaq = () => {
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [description, setDescription] = useState("");
    const [showCustomerPanel, setShowCustomerPanel] = useState();
    const navigate = useNavigate();
    const { id } = useParams();

    const getFaqInfo = () => {
        Http.GET("getFaqInfo", id).then(({ data }) => {
            setTitle(data.title);
            setType(data.type);
            setDescription(data.description);
            setShowCustomerPanel(data.active);
        });
    };

    const handleSubmit = (e) => {
        const data = {
            title: title,
            type: type,
            description: description,
            active: showCustomerPanel,
        };

        Http.PUT("EditFaq", data, id)
            .then((response) => {
                toastSuccess("Successfully Updated");
                navigate("/faq");
            })
            .catch((error) => {
                toastError("Something went wrong");
            });
    };

    useEffect(() => {
        getFaqInfo();
    }, []);

    return (
        <div className="p-5">
            <div className="form-container faq-form-container">
                <h3 className="text-md mb-2">Add faq</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="title" className="text-sm mb-1">
                                    Title*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={title}
                                    name="title"
                                    placeholder="Faq title"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12 mt-4">
                                <label htmlFor="categoryType" className="text-sm mb-1">
                                    Category Type
                                </label>

                                <select
                                    name="categoryType"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="BUY">Buy</option>
                                    <option value="SELL">Sell</option>
                                    <option value="REPAIR">Repair</option>
                                    <option value="GENERAL">General</option>
                                </select>
                            </div>
                            <div className="col-md-12 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Description
                                </label>
                                <textarea
                                    className="form-control form-control-sm"
                                    placeholder="Description"
                                    spellCheck="false"
                                    value={description}
                                    name="title"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        defaultChecked={showCustomerPanel}
                                        className="form-check-input"
                                        checked={showCustomerPanel}
                                        onChange={(e) => setShowCustomerPanel(e.target.checked)}
                                        id="showCustomerPanel"
                                    />
                                    <label className="form-check-label" htmlFor="showCustomerPanel">
                                        Show on customer panel
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFaq;
