import React, { useState, useEffect } from "react";
import ImageUpload from "../../commonComponents/ImageUpload";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

function AddProduct() {
    const [name, setName] = useState("");
    const [brand, setBrand] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [type, setType] = useState("");
    const [categoryType, setCategoryType] = useState(null);
    const [productImage, setProductImage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [childCategories, setChildCategories] = useState([]);
    
    const navigate = useNavigate();

    // const validate = () => {
    //     const errors = {};

    //     if (!name) {
    //         errors.name = "Name is required.";
    //     }

    //     if (Object.keys(productImage).length === 0) {
    //         errors.logo = "Image is required.";
    //     }

    //     setError(errors);

    //     return Object.keys(errors).length === 0;
    // };

    const onUploadProductImage = (image) => {
        setProductImage(image);
    };

    const getBrandList = () => {
        Http.GET("brandList")
            .then((response) => {
                let data = response.data;
                setBrandList(data.data);
            })
            .catch((response) => {
                toastError("Something went wrong!");
            });
    };

    const getCategoryList = (categoryType, index, parentCategory, isRootCategory = false ) => {
        let param = `?type=${categoryType}`;
        if( parentCategory != null )
            param +=`&parentId=${parentCategory}`;
        param +=`&isRootCategory=${isRootCategory}`
        Http.GET("categoryList", param)
            .then((response) => {
                let data = response.data;
                if( data.data.length  < 1 ) return;

                let tempCategoris = [...childCategories.slice(0, index)];
                let temp = {
                    index : index,
                    value : '',
                    categoryList : data.data
                };
                tempCategoris.push( temp );
                setChildCategories( tempCategoris );
            })
            .catch((response) => {
                toastError("Something went wrong!");
            });
    };

    const catergoryTypeChange = (tempCategoryType) => {
        setCategoryType(tempCategoryType);
        getCategoryList( tempCategoryType, 0 , null, true );
    };
    const categoryHandler = ( index, value ) => {
        let tempCategoris = [...childCategories];
        tempCategoris[index].value = value;
        setChildCategories( tempCategoris );
        getCategoryList( categoryType, index + 1, value );
    }

    const handleSubmit = (e) => {
        let categoryId = 0;
        let length = childCategories.length;
        if( length > 1 ) {
            if( childCategories[length -1].value !== '' )
                categoryId = childCategories[length -1].value;
            else
                categoryId = childCategories[length -2].value;
        }else if( length > 0 )
            categoryId = childCategories[length -1].value;
        const data = {
            name: name,
            type: type,
            brandId: parseInt(brand),
            categoryId: parseInt(categoryId),
            featureImageDTO: productImage,
        };

        Http.POST("createProduct", data)
            .then((response) => {
                toastSuccess("Succuessfully added");
                navigate("/product/"+response.data.payload );
            })
            .catch((error) => {
                toastError("Something went wrong");
            });
    };

    useEffect(() => {
        // getCategoryList("BUY", 1);
        getBrandList();
    }, []);

    return (
        <div className="p-5">
            <div className="form-container">
                <h3 className="text-md mb-2">Add Product</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <ImageUpload
                                    onUploadImage={(image) => onUploadProductImage(image)}
                                    docType={"FRONT_IMAGE"}
                                    type={"image"}
                                    isEditable={false}
                                    size={"sm"}
                                    isUploadMulti={false}
                                />

                                {error.logo && <p className="text-danger text-sm">{error.logo}</p>}
                            </div>
                            <div className="col-md-9">
                                <img
                                    className="uploaded-logo border-0"
                                    src={
                                        productImage?.base64Str
                                            ? productImage?.base64Str
                                            : ""
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="name" className="text-sm mb-1">
                                    Product Name*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={name}
                                    name="name"
                                    placeholder="Product name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {error.name && <p className="text-danger text-sm">{error.name}</p>}
                            </div>

                            <div className="col-md-6 mt-2">
                                <label htmlFor="type" className="text-sm mb-1">
                                    Type
                                </label>

                                <select
                                    name="type"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="MOBILE">Mobile</option>
                                    <option value="LAPTOP">Laptop</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Brand
                                </label>

                                <select
                                    name="brand"
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    {brandList &&
                                        brandList.map((item) => (
                                            <option key={item?.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="col-md-6 mt-4">
                                <label htmlFor="cType" className="text-sm mb-1">
                                    Category Type
                                </label>

                                <select
                                    name="cType"
                                    value={categoryType}
                                    onChange={(e) => catergoryTypeChange(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    <option value="BUY">Buy</option>
                                    <option value="SELL">Sell</option>
                                    <option value="REPAIR">Repair</option>
                                </select>
                            </div>

                            { childCategories && childCategories.map( (item) => (
                            <div className="col-md-4 mt-4" key={"main"+item?.index}>
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Child category {item?.index}
                                </label>

                                <select
                                    name="category"
                                    value={item?.value}
                                    onChange={(e) => categoryHandler(item?.index, e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option>Select</option>
                                    {item?.categoryList &&
                                        item.categoryList.map((category) => (
                                            <option key={category?.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            ))}

                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProduct;
