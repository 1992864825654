import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import { BASE_FRONT_END_URL } from "../utils/constant";

const AdminLayout = () => {
    const Logout = () => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        window.location.href = "/login";
    };

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    return (
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
            <nav
                className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar"
                id="sidebar"
            >
                <div className="container-fluid">
                    <button
                        className="navbar-toggler ms-n2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#sidebarCollapse"
                        aria-controls="sidebarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <NavLink to="/" className="navbar-brand mb-1 d-inline-block px-4 py-2">
                        <img src="/img/rebuild-logo.png" alt="logo" className="rebuild-logo" />
                    </NavLink>
                    <div className="navbar-user d-lg-none">
                        <div className="dropdown">
                            <NavLink
                                to="#"
                                id="sidebarAvatar"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <div className="avatar-parent-child">
                                    <img
                                        alt="..."
                                        src="/img/people/img-10.jpg"
                                        className="avatar avatar- rounded-circle"
                                    />
                                    <span className="avatar-child avatar-badge bg-success" />
                                </div>
                            </NavLink>
                            <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="sidebarAvatar"
                            >
                                <NavLink to="#" className="dropdown-item">
                                    Profile
                                </NavLink>
                                <hr className="dropdown-divider" />
                                <span onClick={Logout} className="dropdown-item cursor-pointer">
                                    Logout
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    to="#components"
                                    className="nav-link"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="components"
                                >
                                    User
                                </NavLink>
                                <div className="collapse" id="components">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to="/admin" className="nav-link">
                                                Admin
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/client" className="nav-link">
                                                Customer
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#buyers"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="buyers"
                                >
                                    Components
                                </NavLink>
                                <div className="collapse" id="buyers">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to="/brand" className="nav-link">
                                                Brand
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="categories" className="nav-link">
                                                Category
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/products" className="nav-link">
                                                Products
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/question-bank" className="nav-link">
                                                Question Bank
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/faq" className="nav-link">
                                                Faq
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#orders"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="orders"
                                >
                                    Orders
                                </NavLink>
                                <div className="collapse" id="orders">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to="/orders" className="nav-link">
                                                Order List
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#sellers"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="sellers"
                                >
                                    Sellers
                                </NavLink>
                                <div className="collapse" id="sellers">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to="/sell/phones" className="nav-link">
                                                Smart Phones
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/sell/laptop" className="nav-link">
                                                Laptop
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="flex-lg-1 h-screen overflow-y-lg-auto">
                <nav
                    className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-2"
                    id="topbar"
                >
                    <div className="container-fluid">
                        <div></div>
                        <div className="navbar-user  d-sm-block">
                            <div className="hstack gap-3 ms-4">
                                <div className="dropdown">
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="false"
                                        aria-expanded="false"
                                    >
                                        <div>
                                            <div className="avatar avatar-sm rounded-circle text-white">
                                                <img
                                                    alt="..."
                                                    src={
                                                        userInfo?.profilePicDocument?.docUrl
                                                            ? userInfo?.profilePicDocument?.docUrl
                                                            : "/icons/pro_pic_default.svg"
                                                    }
                                                    className="avatar-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-block ms-3"></div>
                                        <div className="d-none d-md-block ms-md-2">
                                            <i className="bi bi-chevron-down text-muted text-xs" />
                                        </div>
                                    </NavLink>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="dropdown-header">
                                            <span className="d-block text-sm text-muted mb-1">
                                                Signed in as
                                            </span>
                                            <span className="d-block text-heading font-semibold">
                                                {userInfo?.name}
                                            </span>
                                        </div>

                                        <NavLink
                                            className="dropdown-item"
                                            to={`/admins/edit/${userInfo?.id}`}
                                        >
                                            <i className="bi bi-pencil me-3" />
                                            Edit profile
                                        </NavLink>

                                        <div className="dropdown-divider" />
                                        <span
                                            className="dropdown-item cursor-pointer"
                                            onClick={Logout}
                                        >
                                            Logout
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
