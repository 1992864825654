import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { useRef } from "react";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError, toastSuccess } from "../../utils/Toast";

const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginatedData, setPaginatedData] = useState({});
  const [categoryType, setCategoryType] = useState(null);
  const [brand, setBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [type, setType] = useState("");
  const [childCategories, setChildCategories] = useState([]);
  const [showCatDropdown, setShowCatDropdown] = useState(false);
  const navigate = useNavigate();
  const size = 20;
  const categoryRef = useRef(null);

  const addProduct = () => {
    navigate("/products/add");
  };

  const getBrandList = () => {
    Http.GET("brandList")
      .then((response) => {
        let data = response.data;
        setBrandList(data.data);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const addToExclusive = (id, isExclusive) => {
    const data = {
      productId: id,
      isAdded: isExclusive ? false : true,
    };

    Http.POST("addExclusive", data)
      .then((response) => {
        getProductList();
        if (isExclusive) {
          toastSuccess(
            "Product successfully removed from best selling section."
          );
        } else {
          toastSuccess("Product successfully added on best selling section.");
        }
      })
      .catch((error) => {
        toastError("Something went wrong");
      });
  };

  const getProductList = () => {
    setLoading(true);
    let param = `?page=${page}&size=${size}&search=${searchTerm}`;
    if (brand !== null && brand !== "") param += `&brandIds=${brand}`;

    if (categoryType !== null && categoryType !== "")
      param += `&category_type=${categoryType}`;
    let categoryId = null;
    childCategories.forEach((item) => {
      if (item.value !== null && item.value !== "") categoryId = item.value;
    });
    if (categoryId !== null) param += `&categoryIds=${categoryId}`;

    Http.GET("productList", param)
      .then((response) => {
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalElements);
        if (data.currentPage > 0) {
          data.data = [...productList, ...data.data];
        }
        setProductList(data.data);
        setLoading(false);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getProductList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getProductList();
    } else {
      setPage(0);
    }
  };

  const goToProduct = (item) => {
    navigate(`/product/${item}`);
  };

  const getCategoryList = (
    type,
    index,
    parentCategory,
    isRootCategory = false
  ) => {
    let param = `?categoryType=${type}`;
    if (parentCategory != null) param += `&parentId=${parentCategory}`;
    param += `&isRootCategory=${isRootCategory}`;
    Http.GET("categoryList", param)
      .then((response) => {
        let data = response.data;
        if (data.data.length < 1) return;

        let tempCategoris = [...childCategories.slice(0, index)];
        let temp = {
          index: index,
          value: "",
          categoryList: data.data,
        };
        tempCategoris.push(temp);
        setChildCategories(tempCategoris);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const catergoryTypeChange = (type) => {
    setCategoryType(type);
    setChildCategories([]);
    getCategoryList(type, 0, null, true);
  };

  const categoryHandler = (index, value) => {
    let tempCategoris = [...childCategories];
    tempCategoris[index].value = value;
    setChildCategories(tempCategoris);
    if (value !== "") {
      getCategoryList(type, index + 1, value);
      getProductList();
    }
  };

  const checkChildCatLength = () => {
    if (childCategories.length > 0) {
      setShowCatDropdown(true);
    }
  };

  const onDeleteProduct = async (productId) => {
    setLoading(true);
    await Http.DELETE("deleteProduct", {}, `${productId}`)
      .then(({ data }) => {
        setLoading(false);
        let deleteResult = productList.filter((item) => item.id !== productId);
        setProductList(deleteResult);
        toastSuccess("Successfully deleted");
      })
      .catch(({ response }) => {
        setLoading(false);
        toastError("Request was unsuccessful.");
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        setShowCatDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [categoryRef]);

  useEffect(() => {
    getProductList();
  }, [page, brand, categoryType]);

  useEffect(() => {
    checkChildCatLength();
  }, [childCategories]);

  useEffect(() => {
    getBrandList();
  }, []);

  return (
    <LoaderComponent loading={loading}>
      <div className="p-4 bg-white">
        <div className="d-flex productlist-top-header justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {productList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center search">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>

          <div className="type-filters d-flex align-items-center">
            <select
              name="brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              className="form-select form-select-sm"
            >
              <option value={""}>Brand</option>
              {brandList &&
                brandList.map((item) => (
                  <option key={item?.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            <div className="categorie-type" ref={categoryRef}>
              <select
                name="cType"
                value={categoryType}
                onChange={(e) => catergoryTypeChange(e.target.value)}
                className="form-select form-select-sm"
              >
                <option value="">Category Type</option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
                <option value="REPAIR">Repair</option>
              </select>
              {childCategories.length > 0 && showCatDropdown && (
                <div className="child-categories">
                  {childCategories.map((item) => (
                    <div className="mb-3" key={"main" + item?.index}>
                      <label htmlFor="linkedIn" className="text-sm mb-1">
                        Child category {item?.index}
                      </label>

                      <select
                        name="category"
                        value={item?.value}
                        onChange={(e) =>
                          categoryHandler(item?.index, e.target.value)
                        }
                        className="form-select form-select-sm"
                      >
                        <option value="">Select</option>
                        {item?.categoryList &&
                          item.categoryList.map((category) => (
                            <option key={category?.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="btn green btn-sm add rounded-0"
              onClick={addProduct}
            >
              <span className="hide-mobile me-1">Add Product</span>
              <img src="/icons/plus.svg" alt="add" />
            </button>
          </div>
        </div>
        <div
          className="scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th>Product Image</th>
                  <th>Name</th>
                  <th>Category Type</th>
                  <th>Type</th>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {productList &&
                  productList.map((item) => (
                    <tr key={item?.id}>
                      <td onClick={() => goToProduct(item.id)}>
                        <img
                          src={
                            item?.featureImageResponse?.docUrl ||
                            "/img/default.png"
                          }
                          alt="Logo"
                          className="logo"
                        />
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.categoryType}</td>
                      <td>{item?.type}</td>
                      <td>{item?.brandResponse?.name}</td>
                      <td>{item?.categoryResponse?.name}</td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span
                            className="cursor-pointer mx-2"
                            onClick={() => goToProduct(item.id)}
                          >
                            <img src="/icons/edit.svg" alt="edit" />
                          </span>
                          {item?.categoryType === "BUY" && (
                            <span
                              className="cursor-pointer mx-2"
                              onClick={() =>
                                addToExclusive(item.id, item.isExclusive)
                              }
                            >
                              {item?.isExclusive ? (
                                <img
                                  src="/icons/exclusived.svg"
                                  alt="exclisive"
                                />
                              ) : (
                                <img
                                  src="/icons/exclusive.svg"
                                  alt="exclisive"
                                />
                              )}
                            </span>
                          )}
                          <span
                            className="cursor-pointer mx-2"
                            onClick={() => onDeleteProduct(item.id)}
                          >
                            <img src="/icons/delete.svg" alt="delete" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {productList.length === 0 && (
              <p className="empty-state">No product found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default ProductList;
