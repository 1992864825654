import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

const AddAdmin = () => {
    const [fullName, setFullName] = useState("");
    const [nickName, setNickName] = useState("");
    const [email, setEmail] = useState("");
    const [designation, setDesignation] = useState("");
    const [phone, setPhone] = useState("");
    const [status, setStatus] = useState("ACTIVE");
    const [password, setPassword] = useState("");
    const [retypePassword, setretypePassword] = useState("");
    const [userType, setUserType] = useState("ADMIN");
    const [userTypeList, setUserTypeList] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [address, setAddress] = useState("");
    const [error, setError] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        const errors = {};

        if (!email) {
            errors.email = "Email is required.";
        }

        if (!password) {
            errors.password = "Password is required.";
        }

        if (!retypePassword) {
            errors.retypePassword = "Please retype password.";
        }

        if (!fullName) {
            errors.fullName = "Full Name is required.";
        }

        if (!userType) {
            errors.userType = "User Type is required.";
        }

        if (!status) {
            errors.status = "Status is required.";
        }

        setError(errors);

        return Object.keys(errors).length === 0;
    };

    const getUserTypeList = () => {
        Http.GET("adminUserTypeList")
            .then((response) => {
                setUserTypeList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getUserTypeList();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            const data = {
                fullName,
                nickName,
                email,
                designation,
                phone,
                status,
                password,
                retypePassword,
                userType,
                linkedIn,
                address,
            };

            Http.POST("createAdmin", data)
                .then((response) => {
                    toastSuccess("Succuessfully added");
                    navigate("/admin");
                })
                .catch((error) => {
                    toastError("Something went wrong");
                });
        }
    };

    return (
        <div className="p-5">
            <div className="form-container">
                <h3 className="text-md mb-2">Add Admin</h3>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12"></div>
                            <div className="col-md-6">
                                <label htmlFor="fullName" className="text-sm mb-1">
                                    Full Name*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={fullName}
                                    name="fullName"
                                    placeholder="Full name"
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                {error.fullName && (
                                    <p className="text-danger text-sm">{error.fullName}</p>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="nickName" className="text-sm mb-1">
                                    Nick name
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={nickName}
                                    name="nickName"
                                    placeholder="Nick name"
                                    onChange={(e) => setNickName(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="email" className="text-sm mb-1">
                                    Email*
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="email"
                                    value={email}
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {error.email && (
                                    <p className="text-danger text-sm">{error.email}</p>
                                )}
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="designation" className="text-sm mb-1">
                                    Designation
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={designation}
                                    name="designation"
                                    placeholder="Designation"
                                    onChange={(e) => setDesignation(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="phone" className="text-sm mb-1">
                                    Phone
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="number"
                                    value={phone}
                                    name="phone"
                                    placeholder="Phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="name" className="text-sm mb-1">
                                    Status
                                </label>
                                <select
                                    name="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    <option value="ACTIVE" selected>
                                        Active
                                    </option>
                                    <option value="DISABLED">Disable</option>
                                </select>
                                {error.status && (
                                    <p className="text-danger text-sm">{error.status}</p>
                                )}
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="userType" className="text-sm mb-1">
                                    User Type*
                                </label>
                                <select
                                    name="userType"
                                    value={userType}
                                    onChange={(e) => setUserType(e.target.value)}
                                    className="form-select form-select-sm"
                                >
                                    {userTypeList &&
                                        userTypeList.map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                </select>
                                {error.userType && (
                                    <p className="text-danger text-sm">{error.userType}</p>
                                )}
                            </div>

                            <div className="col-md-6 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    LinkedIn
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={linkedIn}
                                    name="linkedIn"
                                    placeholder="LinkedIn url"
                                    onChange={(e) => setLinkedIn(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12 mt-4">
                                <label htmlFor="linkedIn" className="text-sm mb-1">
                                    Address
                                </label>

                                <textarea
                                    className="form-control"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row bg-white shadow p-5 bg-body rounded">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="password" className="text-sm mb-1">
                                    Password
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="password"
                                    value={password}
                                    name="password"
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {error.password && (
                                    <p className="text-danger text-sm">{error.password}</p>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="retypePassword" className="text-sm mb-1">
                                    Confirm Password
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    type="password"
                                    value={retypePassword}
                                    name="retypePassword"
                                    placeholder="Confirm password"
                                    onChange={(e) => setretypePassword(e.target.value)}
                                />
                                {error.retypePassword && (
                                    <p className="text-danger text-sm">{error.retypePassword}</p>
                                )}
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn green btn-sm rounded-0"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdmin;
