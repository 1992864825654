const HTTP_STATUS = {
    OK: 200,
    "Bad Request": 400,
    Unauthorized: 401,
    Forbidden: 403,
    "Not Found": 404,
    "Method Not Allowed": 405,
    "Not Acceptable": 406,
    "Request Time-out": 408,
    Conflict: 409,
    Gone: 410,
    "Unsupported Media Type": 415,
    "Expectation Failed": 417,
    "Precondition Required": 428,
    "Valid OTP Exists": 452,
};

export const BASE_URL = getBaseUrl(); //Live
export const BASE_FRONT_END_URL = getBaseFrontEndUrl();

export const OAUTH2_REDIRECT_URI = window.location.origin + "/oauth2/redirect";

export const GOOGLE_AUTH_URL =
    BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
    BASE_URL + "/oauth2/authorize/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
    BASE_URL + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const LINKEDIN_AUTH_URL =
    BASE_URL + "/oauth2/authorize/linkedin?redirect_uri=" + OAUTH2_REDIRECT_URI;

export const VALID_FILE_SIZE = 150582912; // in binary 150mb

export { HTTP_STATUS };
function getBaseUrl() {
    const hostName = window.location.toString();

    if (hostName.indexOf("https://admin.rebuildtechno.com") > -1) {
        return "https://api.rebuildtechno.com";
    } if (hostName.indexOf("https://testadmin.rebuildtechno.com") > -1) {
        return "https://testapi.rebuildtechno.com";
    } else if (hostName.indexOf("http://localhost") > -1) {
        return "https://testapi.rebuildtechno.com";
        //return 'http://localhost:8080';
    } else {
        return "https://api.rebuildtechno.com";
    }
}

function getBaseFrontEndUrl() {
    const hostName = window.location.toString();

    if (hostName.indexOf("https://test.rebuildtechno.com") > -1) {
        return "https://test.rebuildtechno.com";
    } else if (hostName.indexOf("https://testadmin.rebuildtechno.com") > -1) {
        return "https://testadmin.rebuildtechno.com";
    } else if (hostName.indexOf("https://app.rebuildtechno.com") > -1) {
        return "https://app.rebuildtechno.com";
    } else if (hostName.indexOf("https://admin.rebuildtechno.com") > -1) {
        return "https://admin.rebuildtechno.com";
    } else if (hostName.indexOf("localhost") > -1) {
        return "http://localhost:3001";
    } else if (hostName.indexOf("192.168.0.") > -1) {
        return "http://192.168.0.40:3001";
    } else if (hostName.indexOf("https://admin-beta.rebuildtechno.com") > -1) {
        return "https://admin-beta.rebuildtechno.com";
    } else if (hostName.indexOf("https://demoadmin.rebuildtechno.com") > -1) {
        return "https://demoadmin.rebuildtechno.com";
    }
}
export const isItProductionServer = () => {
    const hostName = window.location.toString();
    if (
        hostName.indexOf("https://admin.rebuildtechno.com") > -1 ||
        hostName.indexOf("https://admin-beta.rebuildtechno.com") > -1
    ) {
        return true;
    }
};

export const isItLocalServer = () => {
    const hostName = window.location.toString();
    if (hostName.indexOf("http://localhost") !== -1) {
        return true;
    }
};

export function getBaseWebSocketUrl() {
    const hostName = window.location.toString();
    if (hostName.indexOf("https://test.rebuildtechno.com") > -1) {
        return "wss://api.rebuildtechno.com/ws";
    } else if (hostName.indexOf("https://testadmin.rebuildtechno.com") > -1) {
        return "wss://testapi.rebuildtechno.com/ws";
    } else if (hostName.indexOf("https://app.rebuildtechno.com") > -1) {
        return "wss://api.rebuildtechno.com/ws";
    } else if (hostName.indexOf("https://admin.rebuildtechno.com") > -1) {
        return "wss://api.rebuildtechno.com/ws";
    } else if (hostName.indexOf("localhost") > -1) {
        return "wss://testapi.rebuildtechno.com/ws";
    } else if (hostName.indexOf("https://admin-beta.rebuildtechno.com") > -1) {
        return "wss://apiv2.rebuildtechno.com/ws";
    } else if (hostName.indexOf("https://demoadmin.rebuildtechno.com") > -1) {
        return "wss://demoapi.rebuildtechno.com/ws";
    }
}

export const LOADER_OVERLAY_BACKGROUND = "transparent";
export const LOADER_COLOR = "#39a536";
export const LOADER_WIDTH = "80px";
export const LOADER_TEXT = ""; //temporarily removed
export const LOADER_POSITION = "fixed";
export const LOADER_TOP = "50%";
export const LOADER_LEFT = "50%";
export const LOADER_MARGIN_TOP = -100;
export const LOADER_MARGIN_LEFT = 0;
