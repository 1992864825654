import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";

const SortingQuestions = ({
    showSortQestion,
    setShowSortQestion,
    productId,
    productQuestionList,
}) => {
    const [questions, setQuestions] = useState([]);
    const generateQuestions = () => {
        let questionList = productQuestionList?.map((item) => {
            let temp = {
                id: "" + item.productQuestionId,
                title: item.title,
            };
            return temp;
        });
        setQuestions(questionList);
    };

    const handleClose = () => setShowSortQestion(false);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;
        const updatedQuestions = Array.from(questions);
        const [removed] = updatedQuestions.splice(source.index, 1);
        updatedQuestions.splice(destination.index, 0, removed);

        setQuestions(updatedQuestions);
    };

    const save = () => {
        let orderingRequest = questions.map((item, index) => {
            return {
                id: item.id,
                serial: index,
            };
        });

        Http.POST("productQuestionOrdering", orderingRequest)
            .then((response) => {
                setShowSortQestion(false);
                toastSuccess("Questions are sorted successfully");
            })
            .catch((error) => {
                toastError("Something went wrong");
            });
    };

    useEffect(() => {
        generateQuestions();
    }, [productQuestionList]);

    return (
        <Modal show={showSortQestion} onHide={handleClose} className="questions-select-modal">
            <Modal.Header closeButton>Sort the questions as you want</Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Qusetions</th>
                                </tr>
                            </thead>
                            <Droppable droppableId="questions">
                                {(provided) => (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {questions.map((question, index) => (
                                            <Draggable
                                                key={question.id}
                                                draggableId={question.id}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <td>{index + 1}</td>
                                                        <td>{question.title}</td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </table>
                    </DragDropContext>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary btn-sm"
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button className="btn green btn-sm" variant="primary" onClick={save}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default SortingQuestions;
