import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError, toastSuccess } from "../../utils/Toast";
import Modal from "react-bootstrap/Modal";

const ClientList = () => {
  const [clientList, setClientList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [retypePassword, setretypePassword] = useState("");
  const [error, setError] = useState({});
  const [idToEdit, setIdToEdit] = useState(null);
  const [paginatedData, setPaginatedData] = useState({});
  const size = 20;

  const getAdminList = () => {
    setLoading(true);
    Http.GET("clientList", `?page=${page}&size=${size}&name=${searchTerm}`)
      .then((response) => {
        setLoading(false);
        let data = response.data;
        setPaginatedData(data);
        setTotalItems(data.totalCount);
        if (data.currentPage > 0) {
          data.data = [...clientList, ...data.data];
        }
        setClientList(data.data);
      })
      .catch((response) => {
        setLoading(false);
        toastError("Something went wrong!");
      });
  };

  const handleListTabledScroll = async () => {
    const wrappedElement = document.getElementById("commonListTable");
    const { scrollHeight, scrollTop, clientHeight } = wrappedElement;

    const scrollDifference = Math.abs(
      scrollHeight - (scrollTop + clientHeight)
    );
    const errorMargin = 1; // Adjust this value based on testing

    if (scrollDifference <= errorMargin) {
      if (
        !loading &&
        paginatedData.currentPage + 1 < paginatedData.totalPages
      ) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Backspace") {
      if (page === 0) {
        getAdminList();
      } else {
        setPage(0);
      }
    }
  };

  const onSearchClick = () => {
    if (page === 0) {
      getAdminList();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    getAdminList();
  }, [page]);

  return (
    <LoaderComponent loading={loading}>
      <div className="p-5 bg-white">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <p className="text-sm me-3">
              Showing {clientList?.length} of {totalItems}
            </p>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control common-list-search"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyUp={(e) => handleKeyPress(e)}
              />
              <button
                className="btn green common-serach btn-sm"
                onClick={() => onSearchClick()}
              >
                <img src="/icons/search.svg" alt="search" />
              </button>
            </div>
          </div>
        </div>
        <div
          className="scroll-y-label"
          id="commonListTable"
          onScroll={handleListTabledScroll}
        >
          <div className="table-responsive">
            <table className="table table-bordered admin-list-table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {clientList &&
                  clientList.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phoneNumber}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {clientList.length === 0 && (
              <p className="empty-state">No admin found.</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default ClientList;
