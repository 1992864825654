import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assests/scss/App.scss";
import AdminLayout from "./layout/AdminLayout";
import Dasdboard from "./modules/dashboard/Dasdboard";
import Login from "./modules/login/Login";
import AddAmdin from "./modules/users/AddAmdin";
import AddCustomer from "./modules/users/AddCustomer";
import AdminList from "./modules/users/AdminList";
import PrivateRoutes from "./utils/PrivateRoutes";
import EditAdmin from "./modules/users/EditAdmin";
import AddBrand from "./modules/brand/AddBrand";
import BrandList from "./modules/brand/BrandList";
import EditBrand from "./modules/brand/EditBrand";
import AddCatergory from "./modules/categories/AddCategory";
import EditCatergory from "./modules/categories/EditCategory";
import CategoryList from "./modules/categories/CategoryList";
import ProductList from "./modules/products/ProductList";
import AddProduct from "./modules/products/AddProduct";
import Product from "./modules/products/Product";
import ClientList from "./modules/users/ClientList";
import QuestionList from "./modules/questions/QuestionList";
import AddAQuestion from "./modules/questions/AddQuestion";
import FaqList from "./modules/faq/FaqList";
import AddFaq from "./modules/faq/AddFaq";
import EditFaq from "./modules/faq/EditFaq";
import OrderList from "./modules/order/OrderList";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<PrivateRoutes />}>
                        <Route path="/" element={<AdminLayout />}>
                            <Route path="/" element={<Dasdboard />} />
                            <Route path="/admin" element={<AdminList />} />
                            <Route path="/addadmin" element={<AddAmdin />} />
                            <Route path="/client" element={<ClientList />} />
                            <Route path="/addcustomer" element={<AddCustomer />} />
                            <Route path="/categories" element={<CategoryList />} />
                            <Route path="/admins/edit/:id" element={<EditAdmin />} />
                            <Route path="/brand" element={<BrandList />} />
                            <Route path="/brands/add" element={<AddBrand />} />
                            <Route path="/brands/edit/:id" element={<EditBrand />} />
                            <Route path="/categories/add" element={<AddCatergory />} />
                            <Route path="/categories/edit/:id" element={<EditCatergory />} />
                            <Route path="/products" element={<ProductList />} />
                            <Route path="/product/:id" element={<Product />} />
                            <Route path="/products/add" element={<AddProduct />} />
                            <Route path="/question-bank" element={<QuestionList />} />
                            <Route path="/add-question" element={<AddAQuestion />} />
                            <Route path="/faq" element={<FaqList />} />
                            <Route path="/faq/add" element={<AddFaq />} />
                            <Route path="/faq/edit/:id" element={<EditFaq />} />
                            <Route path="/orders" element={<OrderList />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
