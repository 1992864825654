import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
    const useAuth = () => {
        const token = localStorage.getItem("token");
        return !!token;
    };
    let auth = useAuth();
    return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
