import React, { useState } from "react";
import Http from "../../utils/Http";
import { toastError, toastInfo, toastSuccess } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleLogin = (token) => {
        localStorage.setItem("token", token);
        navigate("/");
    };
    const handleSubmit = (event) => {
        let body = {
            email,
            password,
            // captchaResponse
        };
        Http.POST("login", body)
            .then(({ data }) => {
                toastSuccess("Successfully Logged In.");
                if (data.accessToken) {
                    localStorage.setItem("isReload", "true");
                    localStorage.setItem("token", data.tokenType + " " + data.accessToken);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("email", email);
                    handleLogin(data.accessToken);
                    Http.GET("userInfo")
                        .then(({ data }) => {
                            localStorage.setItem("userInfo", JSON.stringify(data));
                        })
                        .catch(({ response }) => {
                            if (response && response.data && response.data.message) {
                                toastError(response.data.message);
                            } else {
                                toastError("Couldn't fetch user info.");
                            }
                        });
                } else {
                    //toastError(data.message);
                }
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request wasn't successful");
                }
            });

        /*axios
            .post("${BASE_URL}/api/login", { email, password })
            .then((response) => {
                const token = response.data.token;
                handleLogin(token);
            })
            .catch((error) => {
                setErrorMessage("Wrong email or password");
            });*/
    };
    return (
        <div>
            <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
                        <div className="mt-32 mb-20">
                            <h1 className="ls-tight font-bolder display-6 text-white mb-5">
                                Let’s build something amazing today.
                            </h1>
                            <p className="text-white text-opacity-80">
                                Maybe some text here will help me see it better. Oh God. Oke, let’s
                                do it then.
                            </p>
                        </div>
                        <div className="w-56 h-56 bg-orange-500 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3" />
                    </div>
                    <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
                        <div className="py-lg-16 px-lg-20">
                            <div className="row">
                                <div className="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
                                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                                        <span className="d-inline-block d-lg-block h1 mb-4 mb-lg-6 me-3">
                                            👋
                                        </span>
                                        <h1 className="ls-tight font-bolder h2">
                                            Nice to see you!
                                        </h1>
                                    </div>

                                    <div className="mb-5">
                                        <label className="form-label" htmlFor="email">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <label className="form-label" htmlFor="password">
                                                    Password
                                                </label>
                                            </div>
                                        </div>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <button
                                            onClick={handleSubmit}
                                            className="btn btn-primary w-full"
                                        >
                                            Login
                                        </button>
                                        {errorMessage && (
                                            <p className="text-danger">{errorMessage}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
